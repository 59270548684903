import * as React from "react";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import SEO from "../components/SEO";

import Form from "../components/form";

const Contact = () => {
  const { contactLocalTier } = useStaticQuery(graphql`
    query contactImage {
      contactLocalTier: file(relativePath: { eq: "contact_localtier.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const contactLocalTierImageData = getImage(contactLocalTier);

  return (
    <Layout bg={"gray"} header="white">
      <SEO
        title={"Contact LocalTier"}
        description={
          "Whether You’re Interested In Being A Part Of Our Team Or You’d Like Us To Highlight Your Local Business, Reach Out To Us!"
        }
      />
      <GatsbyImage
        image={contactLocalTierImageData}
        className="h-64 md:h-72 w-full object-cover"
      />

      <section className="bg-white">
        <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 sm:py-12 lg:px-8">
          <div className="py-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-3">
              Put Local Tier to Work for You!
            </h1>
            <p className="text-gray-500 font-normal mb-6 leading-relaxed">
              Our passionate network of independent bloggers live, work and play
              in cities and towns across California. We’re always growing and
              expanding across the Golden State, so whether you’re interested in
              being a part of our team or you’d like us to highlight your local
              business, reach out to us!
            </p>
          </div>
          <div className="py-4">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-3">
              Do You Accept Guest Posts?
            </h2>
            <p className="text-gray-500 font-normal mb-6 leading-relaxed">
              We do! But they must be high quality and not an advertorial-style
              post. Nobody wants to read that kind of stuff. If you’d like to
              submit a guest post with an author byline, please take a moment to
              read our guest blog submission guidelines.
            </p>
          </div>{" "}
          <p className="font-semibold text-gray-500">Are you looking to get listed on LocalTier? <Link to={'/get-listed/'} className="text-brand-600 text-underline">Fill out the Get Listed application!</Link></p>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
